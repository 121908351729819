import { createRouter, createWebHistory } from 'vue-router';
import ProductList from '../components/ProductList.vue';
import OrderCheckout from '../components/OrderCheckout.vue';
import ObbStore from '../view/ObbStore.vue';
const routes = [
  {
    path: '/',
    component: ProductList,
  },
  {
    path: '/checkout/:id',
    name: 'checkout',
    component: OrderCheckout,
  },
  {
    path: '/store',
    name: 'store',
    component: ObbStore,
  },
  {
    path: '/:pathMatch(.*)*',
    component: ProductList,
    beforeEnter: (to) => {
      let cookieValue = to.fullPath.substring(1);
      let expDate = new Date();
      expDate.setMonth(expDate.getMonth() + 1);
      document.cookie =
        'obb_ac=' +
        cookieValue +
        ';expires=' +
        expDate +
        ';domain=.ohbabygames.com;path=/';
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;
