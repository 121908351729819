<template>
  <div class="fixed top-0 left-0 w-full h-full flex justify-center items-center z-[9999] pointer-events-none px-4 bg-[rgb(0,0,0,0.7)]"
    v-if="showModal">
    <div
      class="bg-[#16131b] rounded-lg p-4 flex flex-col gap-6 items-end justify-start relative shadow-[0px_8px_32px_0px_rgba(0,0,0,0.16)] pointer-events-auto"
      :class="modalWidth">
      <div class="flex flex-row gap-2 items-center justify-start self-stretch shrink-0 relative overflow-hidden">
        <div class="rounded flex flex-row gap-2 items-center justify-center shrink-0 relative"
          :class="[modalIconBG, {'bg-[#013226]': type === 'check', 'bg-[#322c3f]': type !== 'check' }]">
          <div class="shrink-0 relative overflow-hidden" :class="modalIconSize">
            <div class="absolute inset-0">
              <img :src="getIconPath()" class="absolute left-0 top-0 overflow-visible" alt="Icon" />
            </div>
          </div>
        </div>

        <div class="text-[#efedf2] text-left font-['Rubik'] font-medium relative flex-1" :class="modalTitleSize">
          <slot name="title" />
        </div>

        <template v-if="close">
          <div class="shrink-0 w-5 h-5 relative overflow-hidden cursor-pointer" @click="$emit('close')">
            <svg class="absolute left-[5px] top-[5px] overflow-visible" width="10" height="10" viewBox="0 0 10 10"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 10L10 0" stroke="#9C93B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 10L0 0" stroke="#9C93B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </template>
      </div>

      <div class="text-[#9c93b3] text-left font-normal font-['Rubik'] relative self-stretch overflow-hidden"
        :class="modalTextSize">
        <slot name="message" />
      </div>

      <div class="flex flex-row gap-2 items-center justify-start self-stretch shrink-0 relative" :class="modalHeight">
        <template v-if="linkLabel">
          <div
            class="flex border-solid border-[#f5af0b] border-b flex-row gap-2 items-center justify-center shrink-0 relative cursor-pointer">
            <div class="flex text-[#f5af0b] text-center font-medium font-['Rubik'] relative items-center justify-center"
              :class="modalLinkSize">{{ linkLabel }}</div>
          </div>
        </template>

        <div class="flex flex-row gap-2 items-center flex-1 relative" :class="justifyButtons">
          <template v-if="button1">
            <div @click="button1Click"
              class="rounded-lg p-[1px] border-solid flex flex-row gap-2 items-center justify-center shrink-0 relative overflow-hidden bg-gradient-to-t from-[#322c3f] via-[#322c3f] to-[#554a6a] cursor-pointer"
              :class="{ 'cursor-auto bg-none': disabled, 'bg-gradient-to-t cursor-pointer': !disabled }" >
              <div class="text-center rounded-lg px-3 bg-[#322c3f] text-gray-100 font-medium font-['Rubik']"
                :class="[modalButtonSize, { 'bg-[#221E2A] pointer-events-none text-[#9C93B34D]': disabled, 'bg-[#322c3f] text-gray-100': !disabled }]">
                <slot name="button1" />
              </div>
            </div>
          </template>

          <template v-if="button2">
            <div v-if="type === 'check'" @click="button2Click"
              class="rounded-lg p-[1px] border-solid mx-auto w-full flex flex-row gap-2 items-center justify-center shrink-0 relative overflow-hidden bg-gradient-to-t from-[#960426] via-[#bf0531] to-[#fa5c81] cursor-pointer"
              :class="{ 'cursor-auto bg-none': disabled, 'bg-gradient-to-t cursor-pointer': !disabled }" >
              <div
                class="text-center rounded-lg bg-gradient-to-t from-[#950426] to-[#ED063D] text-gray-100 font-medium font-['Rubik']"
                :class="[modalButtonSize, {
                  'bg-[#221E2A] pointer-events-none text-[#9C93B34D]': disabled,
                  'bg-gradient-to-t from-[#950426] to-[#ED063D] text-gray-100': !disabled
                  }]">
                <slot name="button2" />
              </div>
            </div>
            <div v-else @click="button2Click"
              class="rounded-lg p-[1px] border-solid flex flex-row gap-2 items-center justify-center shrink-0 relative overflow-hidden bg-gradient-to-t from-[#960426] via-[#bf0531] to-[#fa5c81]"
              :class="{ 'cursor-auto bg-none': disabled, 'bg-gradient-to-t cursor-pointer': !disabled }">
              <div
                class="text-center rounded-lg px-3 font-medium font-['Rubik']"
                :class="[modalButtonSize, {
                  'bg-[#221E2A] pointer-events-none text-[#9C93B34D]': disabled,
                  'bg-gradient-to-t from-[#950426] to-[#ED063D] text-gray-100': !disabled
                }]"><slot name="button2" /></div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    type: {
      type: String,
      default: "info",
    },
    close: {
      type: Boolean,
      default: true,
    },
    linkLabel: {
      type: String,
      default: '',
    },
    button1: {
      type: Boolean,
      default: false,
    },
    button2: {
      type: Boolean,
      default: false,
    },
    button1Click: {
      type: Function,
      default: () => { }
    },
    button2Click: {
      type: Function,
      default: () => { }
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalWidth() {
      return {
        'w-[350px]': this.size === 'small',
        'w-[500px]': this.size === 'large',
      };
    },
    modalIconBG() {
      return {
        'w-6 h-6': this.size === 'small',
        'w-8 h-8': this.size === 'large',
      };
    },
    modalIconSize() {
      return {
        'w-[16px] h-[16px]': this.size === 'small',
        'w-[20px] h-[20px]': this.size === 'large',
      };
    },
    modalTitleSize() {
      return {
        'text-base leading-6': this.size === 'small',
        'text-2xl leading-8': this.size === 'large',
      };
    },
    modalTextSize() {
      return {
        'text-xs leading-4': this.size === 'small',
        'text-base leading-6': this.size === 'large',
      };
    },
    modalButtonSize() {
      if (this.pageWidth > 450) {
        return {
          'py-[10px] px-[12px] text-xs leading-3': this.size === 'small',
          'py-[12px] w-full text-base leading-normal': this.size === 'large',
        };
      } else {
        return {
          'w-[85vw] py-[12px] text-base leading-normal': this.size === 'large' && this.type === 'check',
          'w-[124px] py-[12px] text-base leading-normal': this.size === 'large' && this.type !== 'check',
        };
      }
    },
    modalLinkSize() {
      return {
        'text-xs leading-3': this.size === 'small',
        'text-base leading-6': this.size === 'large',
      };
    },
    modalHeight() {
      return {
        'h-8': this.size === 'small',
        'h-12': this.size === 'large',
      };
    },
    justifyButtons() {
      if (this.pageWidth > 450) {
        return 'justify-end';
      } else return 'justify-center';
    },
  },
  methods: {
    getIconPath() {
      const iconPathMap = {
        info: require('../assets/icons/info.svg'),
        success: require('../assets/icons/success.svg'),
        warning: require('../assets/icons/warning.svg'),
        error: require('../assets/icons/error.svg'),
        lock: require('../assets/icons/lock_modal.svg'),
        check: require('../assets/icons/check.svg')
      };
      return iconPathMap[this.type] || require('../assets/icons/info.svg'); // Show info.svg if type not recognised
    },
    closeModal() {
      this.$emit('close')
    },
    handleResize() {
      this.pageWidth = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  data() {
    return {
      pageWidth: window.innerWidth,
    };
  },
};
</script>