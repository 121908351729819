<template>
  <div class="modal" v-if="showModal">
    <MainModal
      :close="false"
      :type="'check'"
      :size="'large'"
      :showModal="showModal"
      :button2="true"
      :button2Click="redirectToHomePage"
      @close="showModal = false"
    >
      <template v-slot:title>
        <p v-if="giftUser" class="modal-title">Gift Bought Successfully!</p>
        <p v-else class="modal-title">Purchase Successful!</p>
      </template>
      <template v-slot:message>
        <div v-if="error" class="error">{{ error }}</div>
        <div class="flex flex-col items-center justify-center mb-6">
          <img v-if="giftUser"
               src="@/assets/images/gift-product.png"
               alt="Gift Product"
          />
          <img v-else
            src="@/assets/images/stickers/sticker_2.png"
            alt="Stumped Sticker"
          />
        </div>
        <p v-if="giftUser" class="text-base">
          The gift is on its way! Your friend will find their new item waiting in their game inventory.
        </p>
        <p v-else class="text-base">
          After your purchase, new items will appear in your game inventory.
        </p>
        <div class="relative flex flex-col items-end justify-start">
          <div
            class="relative flex flex-col items-start self-stretch justify-center"
          >
            <div
              class="mt-[24px] mb-2 flex flex-row items-center justify-center self-stretch relative"
            >
              <img
                v-if="product.image"
                class="flex-2 mr-[14px] w-[102px] rounded"
                :src="product.image"
                alt=""
              />
              <img
                v-else
                class="flex-2 mr-[14px] w-[102px]"
                src="@/assets/placeholder.png"
                alt=""
              />
              <div class="inline-flex items-end justify-between w-full h-12">
                <div
                  class="inline-flex flex-col items-start self-stretch justify-between grow shrink basis-0"
                >
                  <div v-if="giftUser"
                       class="text-[#9C93B3] text-sm font-normal font-['Rubik'] leading-tight"
                  >
                    {{ giftUser }}
                  </div>
                  <div v-else
                    class="text-[#9C93B3] text-sm font-normal font-['Rubik'] leading-tight"
                  >
                    {{ userData ? userData.displayName : "" }}
                  </div>
                  <div
                    class="text-[#EFEDF2] text-sm font-normal font-['Rubik'] leading-tight"
                  >
                    {{ product.name }}
                  </div>
                </div>
                <div class="inline-flex flex-col items-end justify-start gap-1">
                  <div
                    v-if="!isEth"
                    class="text-[#EFEDF2] text-sm font-normal font-['Rubik'] leading-tight"
                  >
                    ${{ taxFree }}
                  </div>
                  <div
                    v-if="isEth"
                    class="text-[#EFEDF2] text-sm font-normal font-['Rubik'] leading-tight"
                  >
                    {{ product.eth_price }} ETH
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="relative flex flex-col items-start justify-start gap-0 shrink-0"
          >
            <div
              v-if="!isEth"
              class="py-3 flex flex-row items-start justify-between shrink-0 relative w-[257px] border-t border-[#322C3F]"
            >
              <div
                class="text-[#9C93B3] text-sm font-normal font-['Inter',sans-serif] leading-tight"
              >
                Sales tax (6.5%)
              </div>
              <div
                class="text-[#9C93B3] text-sm font-normal font-['Inter',sans-serif] leading-tight"
              >
                ${{ tax }}
              </div>
            </div>
            <div
              class="py-3 flex flex-row items-start justify-between shrink-0 relative w-[257px] border-t border-[#322C3F]"
            >
              <div
                class="text-[#EFEDF2] text-sm font-normal font-['Rubik'] leading-tight"
              >
                Total
              </div>
              <div
                v-if="!isEth"
                class="text-[#EFEDF2] text-sm font-normal font-['Rubik'] leading-tight"
              >
                <p v-if="product.sale_price">${{ product.sale_price }}</p>
                <p v-else>${{ product.price }}</p>
              </div>
              <div
                v-if="isEth"
                class="text-[#EFEDF2] text-sm font-normal font-['Rubik'] leading-tight"
              >
                <p>{{ product.eth_price }} ETH</p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:button2> Continue shopping</template>
    </MainModal>
  </div>
</template>

<script>
import MainModal from "./MainModal.vue";
import envConfig from "../config";
import axios from "axios";

export default {
  components: {
    MainModal,
  },
  data() {
    return {
      inputValue: "",
      error: null,
      showModal: true,
      baseUrl: `${envConfig.baseUrl}`,
      userData: null,
      // Below for faster debugging
      // product: {
      //   name: "test",
      //   saleprice: 1,
      // }
    };
  },
  props: {
    product: {
      required: true,
    },
    tax: {
      type: Number,
      required: true,
    },
    taxFree: {
      type: Number,
      required: true,
    },
    salePrice: {
      type: Number,
      required: true,
    },
    fullPrice: {
      type: Number,
      required: true,
    },
    isEth: {
      type: Boolean,
      default: false,
    },
    giftUser: {
      type: String,
      default: null,
    }
  },
  methods: {
    redirectToHomePage() {
      if (this.$route.query.from === "package") {
        window.location.href = envConfig.websiteUrl + `/profile`;
      } else {
        window.location.href = "/";
      }
    },
    fetchUser() {
      const url = envConfig.baseUrl + "/api/user";

      if (this.userToken) {
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            const data = response.data;
            this.userData = data.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    this.userToken = window.getCookie("obb_ck");
    this.fetchUser();
  },
};
</script>

<style scoped>
.modal {
  border: 1px solid black;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.link {
  float: right;
  text-decoration: underline;
  cursor: pointer;
}
</style>
