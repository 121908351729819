<template>
  <div class="modal" v-if="showModal">
    <MainModal 
    :close="true"
    :type="'lock'" 
    :size="'large'" 
    :showModal="showModal" 
    :disabled="inputValue.length > 2 && inputValue.length < 17? false : true"
    :button2="true" 
    :button2Click="saveUsername"
    @close="close()" >
      <template v-slot:title>
        <p class="modal-title">OBB Username</p>
      </template>
      <template v-slot:message>
        <div class="flex flex-col items-center justify-center mb-6">
          <img src="@/assets/images/stickers/sticker_8.png" alt="Stumped Sticker">
        </div>
        <p class="text-base">Enter your own username to buy items for yourself. Or, enter any friend's username to buy them gifts!</p>
        <FlexibleInput 
        class="pt-6"
        v-on:emitToParent="handleInput($event)"
        :size="'large'" 
        :error="errorStatus"
        :placeholder="'Enter OBB Username'"
        :hint="hintStatus" 
        :hintText="'Username cannot be empty.'" 
        :success="false" 
        :successText="'Success text goes here.'"
        :warning="errorStatus"
        :warningText="'Oops! It looks like there\'s an issue with the username you entered. Please double-check and make sure it\'s correct.'" />
      </template>
      <template v-slot:button2>
        Save
      </template>
    </MainModal>
  </div>
</template>

<script>
import MainModal from './MainModal.vue';
import FlexibleInput from './Inputs/FlexibleInput.vue'

export default {
  components: {
    MainModal,
    FlexibleInput,
  },
  data() {
    return {
      inputValue: '',
      showModal: true,
      hintStatus: false,
      errorStatus: false,
    };
  },
  methods: {
    saveUsername() {
      if (this.inputValue.length > 2 ) {
        // Save the username in localStorage
        localStorage.setItem('username', this.inputValue);
        // Hide the modal
        this.showModal = false;
        // Emit an event
        this.$emit('username-saved');
        //this.$router.go();
      }
    },
    handleInput(input) {
      this.inputValue = input
      if (this.inputValue.length > 1) {
        this.errorStatus = false
      }
    },
    close() {
      this.$emit('close')
    },
  },
  watch: {
    hintStatus() {
      if (this.hintStatus) {
        setTimeout(() => (this.hintStatus = false), 2500);
      }
    }
  }
};
</script>

<style scoped>
@media screen and (max-width: 400px) {
  .modal-title {
    font-size: 20px;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.modal-content {
  background: #333131;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
.username-field {
  color: black;
}
input::placeholder {
  opacity: 0.93;
}
</style>
