<template>
  <div class="flex flex-col overflow-hidden">
    <div :class="[
      containerSize,
      { 'focused': isFocused, 'error': error && !disabled, 'disabled': disabled && !error },
      ,
    ]">
      <img class="w-4 h-4" v-if="leftIcon" src="@/assets/icons/rotate-left.svg" alt="Rotate left">
      <input :class="[inputSize, { 'disabled': disabled }]" @focus="handleFocus" @blur="handleBlur"
        :placeholder="placeholder" :disabled="disabled" v-model="inputValue" @input="emitToParent()" />
      <img class="w-4 h-4" v-if="rightIcon1" src="@/assets/icons/rotate-left.svg" alt="Rotate left">
      <img class="w-4 h-4" v-if="rightIcon2" src="@/assets/icons/rotate-left.svg" alt="Rotate left">
    </div>

    <div class="h-6 pt-2 justify-start items-start gap-2 inline-flex" v-if="hint">
      <div class="grow shrink basis-0 text-[#9C93B3] text-xs font-normal font-['Rubik'] leading-none">{{ hintText }}</div>
    </div>

    <div class="h-7 pt-1 rounded justify-start items-end gap-1 inline-flex" v-if="success">
      <div class="grow shrink basis-0 h-6 pr-1 py-1 rounded justify-start gap-1 flex items-center">
        <div class="w-4 h-4 relative">
          <img class="w-4 h-4 left-0 top-0 absolute" src="@/assets/icons/success.svg" alt="Success">
        </div>
        <div class="text-[#06EDB6] text-xs font-normal font-['Rubik'] leading-none">{{ successText }}</div>
      </div>
    </div>

    <div class="h-7 pt-1 rounded justify-start items-end gap-1 inline-flex" v-if="info">
      <div class="grow shrink basis-0 h-6 pr-1 py-1 rounded justify-start gap-1 flex items-center">
        <div class="w-4 h-4 relative">
          <img class="w-4 h-4 left-0 top-0 absolute" src="@/assets/icons/info.svg" alt="Info">
        </div>
        <div class="text-[#50BEFC] text-xs font-normal font-['Rubik'] leading-none">{{ infoText }}</div>
      </div>
    </div>

    <div class="pt-1 rounded justify-start items-end gap-1 inline-flex" v-if="warning">
      <div class="grow shrink basis-0 pr-1 py-1 rounded justify-start gap-1 flex items-center relative">
        <div class="w-4 mr-[6px]">
          <img class="w-4 left-0 top-1 absolute" src="@/assets/icons/error.svg" alt="Warning">
        </div>
        <div class="text-[#F5AF0B] mt-[2px] text-xs font-normal font-['Rubik'] leading-none">{{ warningText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFocused: false,
      inputValue: '',
    };
  },

  props: {
    size: {
      type: String,
      default: 'large',
    },
    placeholder: {
      type: String,
      default: 'Content',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: Boolean,
      default: false,
    },
    hintText: {
      type: String,
      default: ''
    },
    success: {
      type: Boolean,
      default: false,
    },
    successText: {
      type: String,
      default: ''
    },
    info: {
      type: Boolean,
      default: false,
    },
    infoText: {
      type: String,
      default: ''
    },
    warning: {
      type: Boolean,
      default: false,
    },
    warningText: {
      type: String,
      default: ''
    },
    leftIcon: {
      type: Boolean,
      default: false,
    },
    rightIcon1: {
      type: Boolean,
      default: false,
    },
    rightIcon2: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    containerSize() {
      return this.size === 'small' ? 'small-size' : 'large-size';
    },
    inputSize() {
      return this.size === 'small' ? 'small-input' : 'large-input';
    },
  },

  methods: {
    handleFocus(event) {
      if (this.disabled) {
        event.preventDefault();
      } else {
        this.isFocused = true;
      }
    },
    handleBlur() {
      this.isFocused = false;
    },
    emitToParent() {
      this.$emit("emitToParent", this.inputValue)
    }
  },
};
</script>

<style scoped>
input {
  all: unset;
  width: 100%;
}

.focused.small-size {
  border-radius: 8px;
  border: 2px solid #B9B1C9;
  background: #221E2A;
}

.focused.large-size {
  border-radius: 8px;
  border: 2px solid #B9B1C9;
  background: #221E2A;
}

.small-size {
  background: #221e2a;
  border-radius: 8px;
  border-style: solid;
  border-color: #483f5a;
  border-width: 1px;
  padding: 6px 9px 6px 9px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.large-size {
  background: #221e2a;
  border-radius: 8px;
  border-style: solid;
  border-color: #483f5a;
  border-width: 1px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.small-input {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  position: relative;
  color: #EFEDF2;
  text-align: left;
  font: var(--desktop-body-medium, 400 14px/20px "Rubik", sans-serif);
  position: relative;
  flex: 1;
}

.large-input {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  position: relative;
  color: #EFEDF2;
  text-align: left;
  font: var(--desktop-body-large, 400 16px/24px "Rubik", sans-serif);
  position: relative;
  flex: 1;
}

/* Input placeholder text */
::placeholder {
  color: #9C93B3;
}

input:focus::placeholder {
  color: #9C93B34D;
}

input[disabled]::placeholder {
  color: #9C93B34D;
}

input[disabled] {
  color: #9C93B34D;
}

.disabled.small-input,
.disabled.large-input {
  cursor: default;
}

.error {
  border: 1px solid #F5AF0B;
}

.focused.error {
  border: 2px solid #F5AF0B;
}</style>