import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css';
import axios from 'axios';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';



window.getCookie = function (cookieName) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim(); // Remove leading/trailing spaces
    if (cookie.startsWith(cookieName + '=')) {
      let ck = cookie.substring(cookieName.length + 1); // Add 1 to skip the '=' character

      if (ck.includes('%')) {
        ck = decodeURI(ck);
      }

      return ck;
    }
  }
  return null;
};

const authToken = window.getCookie('obb_ck');

if (authToken) {
  axios.defaults.headers.common['Authorization'] = 'Bearer '.concat(authToken);
}

function handleRoute() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const refParam = urlParams.get('ref');

  if (refParam) {
    let cookieValue = refParam;
    let expDate = new Date();
    expDate.setMonth(expDate.getMonth() + 1);
    document.cookie =
      'obb_ac=' +
      cookieValue +
      ';expires=' +
      expDate +
      ';domain=.ohbabygames.com;path=/';
  }
}

handleRoute();

const app = createApp(App);
app.use(router).mount('#app');
app.use(ElementPlus, { size: 'small', zIndex: 3000 });