<template>
  <div
      :class="{ 'fullscreen-loader': fullscreen }"
      style="display: flex; flex-direction: column; justify-content: center; align-items: center">
    <div style="position: relative; width: 110px; height: 110px">
      <div class="loader-container">
        <div class="loader">
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
        </div>
      </div>
    </div>
    <div v-if="loadingMessage" style="font-size: 25px; margin-top: 40px">
      {{ loadingMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: "loader-two",
  props: {
    fullscreen: {
      default: false,
    },
    pause: {
      default: false,
    },
    withMessage: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: null,
    },
  },
  computed: {
    loadingMessage() {
      let message = this.message;

      if (this.withMessage && !message) {
        message = "Please wait";
      }

      return message;
    },
  },
};
</script>

<style scoped>
.loader {
  width: 80px;
  height: 80px;
  position: relative;
}

.loader .circle {
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  animation: pulse 1s infinite ease-in-out;
}

.loader .circle:nth-child(1) {
  top: 0;
  left: 30px;
  background-color: #ed063d;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.loader .circle:nth-child(2) {
  top: 30px;
  left: 60px;
  background-color: #950426;
  animation-delay: 0.25s;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.loader .circle:nth-child(3) {
  top: 60px;
  left: 30px;
  background-color: #ed063d;
  animation-delay: 0.5s;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.loader .circle:nth-child(4) {
  top: 30px;
  left: 0;
  background-color: #950426;
  animation-delay: 0.75s;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@keyframes pulse {
  0% {
    transform: scale(1.35);
  }
  25%,
  50%,
  75% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.fullscreen-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: black;
}
</style>
