<template>
  <div class="w-full h-full flex justify-center items-center z-[9999]">
    <div id="normal-card" class="grid bg-[#16131B] rounded gap-[12px] pb-[16px] relative" :class="cardSize">
      <div v-if="locked || (product.global_purchase_limit != null && product.global_purchase_limit == 0)" class="badge absolute right-2 top-2 z-[1]">
        <div class="flex button-small bg-[#F7BD36] rounded-3xl p-2">
          <div v-if="locked" class="flex">
            <img src="@/assets/icons/lock.svg" alt="lock-icon" class="flex w-3 h-3 mr-[4px]" />
            <span class="flex text-[#181101]">Locked</span>
          </div>
          <div v-else-if="product.global_purchase_limit != null && product.global_purchase_limit == 0">
            <span class="flex text-[#181101]">Sold out</span>
          </div>
        </div>
      </div>
      <img class="card-img w-[100%] h-[160px] md:h-[218px] rounded-t" :src="product.image || getImagePath()" alt="card-img" />

      <section class="flex-col gap-[12px] items-start px-[24px] bg-[#16131B]">
        <div class="flex h-4">
          <div v-if="acquired" class="flex button-small acquired-message text-[#9c93b3] text-left text-[12px] body-small self-stretch max-h-[16px]">
            <div class="flex bg-[#2EFACA] rounded-3xl px-[4px] py-[2px]">
              <img src="@/assets/icons/check-b.svg" alt="check-icon" class="flex w-3 h-3 mr-[2px]" />
              <span class="flex" style="color: #011913">
                Acquired
              </span>
            </div>
          </div>
          <div
              class="flex remaining-message text-[#9c93b3] text-left text-[12px] body-small self-stretch max-h-[16px]">
            <div
                v-if="product.global_purchase_limit != null && product.global_purchase_limit > 0"
                class="button-small rounded-xl w-fit flex justify-center items-center">
            <span v-if="product.global_purchase_limit <= 10" style="color: #e83535" class="bg-[#28010A] rounded-3xl px-[4px] py-[2px]">
              Only {{ product.global_purchase_limit }} remaining
            </span>
              <span v-else style="color: #f5af0b" class="bg-[#312302] rounded-3xl px-[4px] py-[2px]">
              {{ product.global_purchase_limit }} remaining
            </span>
            </div>
          </div>
        </div>

        <div class="text-[#efedf2] text-left heading-small relative flex flex-1 pb-2 pt-1">
          <slot name="title" />
        </div>

        <div class="message text-[#9c93b3] body-medium relative self-stretch h-[95px] md:h-[60px] overflow-y-hidden text-left">
          <slot name="message" />
        </div>

        <div class="flex heading-small justify-between align-center items-center h-[48px] w-[100%] mt-[8px]">
          <div class="flex">
            <h3>$</h3>
            <slot name="price" />
          </div>

          <div v-if="product.global_purchase_limit == null || product.global_purchase_limit > 0" class="flex">
            <button
              id="checkout-button"
              v-if="icon"
              type="secondary"
              :disabled="!buyButton"
              :class="buyButton?'':'primaryDisabled'"
              class="rounded flex justify-center align-center h-[48px] p-[12px]"
              @click="goToCheckout(product, false)">
              <span class="button-large">Buy</span>
            </button>
            <button
                id="gift-button"
                v-if="giftButton"
                type="secondary"
                class="rounded flex justify-center align-center w-[48px] h-[48px] p-[12px] ml-3"
                @click="goToCheckout(product, true)">
              <img class="h-[24px] w-[24px]" style="filter: brightness(0) saturate(100%) invert(100%) sepia(12%) saturate(1960%) hue-rotate(183deg) brightness(97%) contrast(95%);" src="@/assets/icons/gift.svg" alt="shopping cart" />
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// import ChipComponent from "./ChipComponent.vue";
import envConfig from "../../config";

export default {
  props: {
    label: String,
    specialDeals: Boolean,
    icon: Boolean,
    giftButton: Boolean,
    locked: {
      type: Boolean,
      default: false,
    },
    buyButton: {
      type: Boolean,
      default: true,
    },
    acquired: {
      type: Boolean,
      default: false,
    },
    isFocused: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "normal-large-card",
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baseUrl: `${envConfig.baseUrl}`,
    };
  },
  methods: {
    goToCheckout(product, gift) {
      if (gift) {
        this.$router.push({ name: "checkout", params: { id: product.id }, query: { showUser: true } });
      }
      else {
        this.$router.push({ name: "checkout", params: { id: product.id } });
      }
    },
    getImagePath() {
      return require("../../assets/placeholder.png");
    },
  },

  computed: {
    cardSize() {
      return {
        "w-[416px] h-[434px]": this.size === "normal-large-card",
        "w-[288px] h-[434px]": this.size === "normal-small-card",
      };
    },
  },
};
</script>

<style>
#checkout-button, #gift-button {
  background-color: #322C3F;
  border-width: 1px;
  border-color: transparent;
  border-radius: 8px;
  border-bottom: none;
  box-shadow: 0px 1px 0.5px 0px rgba(14, 13, 18, 0.15);
}

#checkout-button.primaryDisabled {
  cursor: not-allowed;
}

#checkout-button:hover, #gift-button:hover {
  background-color: #483F5A;
}

#checkout-button.primaryDisabled:hover {
  background-color: #322C3F;
}

@media screen and (max-width: 767px) {
  #normal-card {
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    height: auto;
    gap: 14px;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
}
</style>
