<template>
  <div class="modal" v-if="showModal">
    <MainModal :close="false" :type="'lock'" :size="'large'" :showModal="showModal" :button1="true" :button2="true"
      :button1Click="signup" :button2Click="login" @close="close()">
      <template v-slot:title>
        <p class="modal-title font-Rubik">Oh baby!</p>
      </template>
      <template v-slot:message>
        <div v-if="error" class="error">{{ error }}</div>
        <div class="flex flex-col items-center justify-center mb-6">
          <img src="@/assets/images/stickers/sticker_8.png" alt="">
        </div>
        <p class="text-base leading-6 font-Rubik">Please log in or sign up before making your purchase.</p>
      </template>
      <template v-slot:button1>
        Don't have an account?
      </template>
      <template v-slot:button2>
        Login
      </template>
    </MainModal>
  </div>
</template>

<script>
import envConfig from '@/config';
import MainModal from './MainModal.vue';

export default {
  components: {
    MainModal,
  },

  data() {
    return {
      error: null,

      modalTitle: 'Title',
      leftButton: 'Button1',
      rightButton: 'Button2',
    };
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    signup() {
      window.location.href = `${envConfig.websiteUrl}/login?signup`;
    },
    login() {
      window.location.href = `${envConfig.websiteUrl}/login?from=checkout:${this.$route.params.id}`
    },
    close() {
      this.$emit('close')
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 400px) {
  .modal-title {
    font-size: 20px;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

input::placeholder {
  opacity: 0.93;
}

p>a {
  color: #A855F7;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
</style>