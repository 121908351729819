<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",

  head: {
    script: [{ src: "https://js.stripe.com/v3" }],
  },
};
</script>

<style>
body {
  background-color: #121212;
  color: white;
  font-family: "Rubik", sans-serif;
}
@import "../src/components/ButtonStyle.css";
</style>
